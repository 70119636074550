import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 5vw;
`;

export const Header = styled.h1`
    font-size: 3vw;
    font-weight: bold;
    margin: 2vw 0;
    color: ${Colors.commons.black};
`;

export const BadgeList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`;

export const Footer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${Colors["light-blue"][100]};
    padding: 16px;
    width: 100%;
    text-align: center;
`;

export const FooterText = styled.span`
    font-size: 3vw;
    color: ${Colors.commons.black};
`;

export const BoldText = styled.span`
    font-weight: bold;
`;
