import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const Container = styled.div`
    display: flex;
    align-items: center;
    background-color: ${Colors.primary[40]};
    border-radius: 8px;
    padding: 1vw;
    margin: 0.5vw 0;
    border-color: ${Colors.primary[100]};
    border-width: 1px;
    border-style: solid;
`;

export const PositionContainer = styled.div`
    background-color: ${Colors.primary[100]};
    padding: 0.8vw;
    border-radius: 8px;
    margin-right: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PositionText = styled.span`
    color: ${Colors.commons.white};
    font-size: 2vw;
    font-weight: bold;
`;

export const PlateContainer = styled.div`
    flex: 1;
`;

export const PlateText = styled.span`
    color: ${Colors.primary[100]};
    font-size: 3vw;
    font-weight: bold;
`;
