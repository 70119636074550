import styled from "styled-components";
import { Colors } from "../../styles/Colors";

export const HomeWrapper = styled.div`

`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-top: 0px;
`;

export const SalesOptionWrapper = styled.div`
  margin: 20px;
`;

export const UserNameText = styled.p`
  margin-left: 20px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: bold;
  color: ${Colors.primary[100]};
`;

export const StationText = styled.p`
  margin: 0px;
  margin-left: 20px;
`;
